import * as React from 'react';
import { graphql, navigate } from 'gatsby';
import { isAuthenticated } from '../utils/auth';
import BlogBody from '../components/BlogBody';
import { BlogPostProps } from './BlogPost';

export const pageQuery = graphql`
  query PrivateBlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
        siteUrl
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
      }
    }
  }
`;

const isBrowser = typeof window !== 'undefined';

const PrivateBlogPostTemplate = (props: BlogPostProps): React.ReactElement => {
  if (!isBrowser) return null;

  if (!isAuthenticated()) {
    navigate('/unauthorized');
    return null;
  }

  const { siteUrl } = props.data.site.siteMetadata;
  const postPath = props.path;
  const post = props.data.mdx;

  const disqusConfig = {
    url: `${siteUrl}${postPath}`,
    identifier: post.id,
    title: post.frontmatter.title,
  };

  const siteTitle = props.data.site.siteMetadata.title;
  const { previous, next } = props.pageContext;

  return (
    <BlogBody
      location={props.location}
      siteTitle={siteTitle}
      previous={previous}
      next={next}
      disqusConfig={disqusConfig}
      post={post}
    />
  );
};

export default PrivateBlogPostTemplate;
